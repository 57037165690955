import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../../components/LayoutFund"
import { theme } from "../../components/Theme"

/** static sections */
import FAQInvestSection from "../../components/static-sections/FAQInvestSection"
import InvestLegalSection from "../../components/static-sections/InvestLegalSection"
import InvestApplyMockConcept from "../../components/static-sections/InvestApplyMockConcept"
import InvestOfferings from "../../components/static-sections/InvestOfferings"
import IraOfferings from "../../components/static-sections/IraOfferings"
import HeroInvestGallery from "../../components/static-sections/HeroInvestGallery"
import HeroOakdaleSection from "../../components/static-sections/HeroOakdaleSection"
import HeroOakdaleGallerySection from "../../components/static-sections/HeroOakdaleGallerySection"
import FundLeadershipTeamSection from "../../components/static-sections/FundLeadershipTeamSection"

/** dynamic sections */
import FundRibbon from "../../components/dynamic-sections/FundRibbon"
import RibbonSection from "../../components/dynamic-sections/RibbonSection"
import HeroBottomInvestTextSection from "../../components/dynamic-sections/HeroBottomInvestTextSection"
import HeroInvestImageHero from "../../components/dynamic-sections/HeroInvestImageHero"
import HeroVideoFund from "../../components/dynamic-sections/HeroVideoFund"
import HeroImage from "../../components/dynamic-sections/HeroImage"
import HeroInvestImage from "../../components/dynamic-sections/HeroInvestImage"
import InvestRibbionSignup from "../../components/dynamic-sections/InvestRibbionSignup"
import HeroBottomInvestLandingCustomSection from "../../components/dynamic-sections/HeroBottomInvestLandingCustomSection"
import HeroBottomInvestLandingWhyInvestSection from "../../components/dynamic-sections/HeroBottomInvestLandingWhyInvestSection"
import TestimonialSection from "../../components/dynamic-sections/testimonial-setcion/TestimonialSection"
import InvestCalculatorSectionNew from "../../components/dynamic-sections/InvestCalculatorSectionNew"
import FundStats from "../../components/dynamic-sections/FundStats"
import InvestRates from "../../components/dynamic-sections/InvestRates"
import HeroRightSection from "../../components/dynamic-sections/HeroRightSection"
import HeroInvestRightSection from "../../components/dynamic-sections/HeroInvestRightSection"
import HeroInvestLinkButtons from "../../components/dynamic-sections/HeroInvestLinkButtons"
import HeroBottomIFundTextSection from "../../components/dynamic-sections/HeroBottomIFundTextSection"

/** svg */
import NorhartBlackCircleIcon from "../../../assets/norhart-black-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.invest

/** props */
interface Props {
  data?: any
  didSubmit: boolean
}

/** const */
export const fundWelcomeSection: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/fund/home",
          name: "Home| Norhart Fund",
          image: `${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Home| Norhart Fund"
      description=""
      keywords=""
      imageTwitter={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroVideoFund
        title="Residential Real Estate Specialist"
        subtitle="30 years of experience. Discover our firm's historical moments, innovation in real estate, longstanding partnerships, and special customer connections."
        buttonName1="Meet The Team"
        buttonUrl1="/fund/leadership/mike-kaeding"
        videoID="1"
        videoFile="/fund/video/hero.mp4"
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomIFundTextSection
        backgroundColorOne="#f0f0f0"
        backgroundColorTwo="#f0f0f0"
        icon={NorhartBlackCircleIcon}
        iconTitle="LEADING REAL ESTATE INVESTMENT MANAGERS"
        title="Exceptional Performance"
        subTitle="Norhart was established with the vision to innovate and lead in redefining traditional approaches; to envision a broader perspective on how individuals aspire to invest and reside – both presently and in the years ahead. Our foundation rests on a worldwide vertically integrated framework, seamlessly merging investment, asset management, development, and property management facets."
        subNote=""
        image=""
        imageAlt="Norhart Invest How It Works"
        textColor="#111111"
        asoEffect="fade-in"
      />

      <FundStats />

      <HeroInvestImageHero imageHero="/fund/oakdale/norhart-oakdale-justin-bartak-design.png" />

      <FundRibbon
        company="NORHART"
        title="Residential Real Estate Specialist"
        subtitle="We design spaces that provide financial, social, and sustainable value to both our partners and customers. We are also proud of our ongoing evolution."
        buttonName="Invest Now"
        buttonUrl="https://invest.norhart.com/signup"
      />

      <HeroInvestGallery
        image1="/fund/oakdale/norhart-oakdale-rooftop-zen-garden-justin-bartak-design.png"
        imageAlt1="Norhart Oakdale MN Apartment Lobby"
        image2="/fund/oakdale/norhart-oakdale-gym-justin-bartak-design.png"
        imageAlt2="Norhart Oakdale MN Apartment Pool"
        image3="/invest/gallery/oakdale/norhart-oakdale-lifestyle-1.jpg"
        imageAlt3="Norhart Oakdale Lifestyle Living"
        image4="/invest/gallery/oakdale/norhart-oakdale-lifestyle-2.jpg"
        imageAlt4="Norhart Oakdale Lifestyle Living"
        image5="/invest/gallery/oakdale/norhart-oakdale-lifestyle-3.jpg"
        imageAlt5="Norhart Oakdale Lifestyle Living"
        image6="/invest/gallery/oakdale/norhart-oakdale-lifestyle-4.jpg"
        imageAlt6="Norhart Oakdale Lifestyle Living"
      />

      <FundLeadershipTeamSection colorPalette={colorPalette} />

      <HeroInvestImageHero imageHero="/fund/oakdale/norhart-oakdale-penthouse-justin-bartak-design.png" />

      <HeroBottomInvestTextSection
        backgroundColorOne="#f0f0f0"
        backgroundColorTwo="#f0f0f0"
        icon={NorhartBlackCircleIcon}
        iconTitle="Long-Term Financial Value"
        title="Thirving Communities"
        subTitle="Our vision is to generate lasting financial and social benefits by constructing flourishing communities around real estate. To fulfill this goal, we realized the necessity of establishing a distinct type of real estate company with a unique approach."
        image=""
        imageAlt="Norhart Invest How It Works"
        textColor="#111111"
        asoEffect="fade-in"
      />

      <HeroInvestImageHero imageHero="/fund/oakdale/norhart-oakdale-bedroom-justin-bartak-design.png" />

      <FundRibbon
        company="NORHART"
        title="Vibrant Culture"
        subtitle="At the heart of our organization is a vibrant culture guided by a diverse and highly skilled team of professionals drawn from various fields, including the real estate industry.
      
        We foster a collaborative environment where our teams operate in synergy rather than isolation. Through vertical integration, we leverage collective knowledge to effectively and consistently generate value at every phase of the asset life cycle."
        buttonName="Invest Now"
        buttonUrl="https://invest.norhart.com/signup"
      />

      <HeroVideoFund
        title="Knowledge Keeps Us Agile"
        subtitle="We manage every activity that benefits our assets and customers. We have a strong local footprint in our markets and deliver unrivalled consumer and investment insights."
        buttonName1=""
        buttonUrl1=""
        videoID="1"
        videoFile="/fund/video/agile.mp4"
      />
    </Layout>
  )
}

/** export */
export default fundWelcomeSection
