import React from "react"
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  imageHero?: string
}

/** const */
const HeroInvestImageHero: React.FC<Props> = (props) => {
  return (
    <div
      className="container-fluid text-center hero p-0 m-0 border-1px"
      style={{
        color: "#FFF",
        backgroundColor: "#FFF",
      }}
    >
      <div className="jumbotron text-center">
        <div className="w-100">
          <div>
            <img
              src={props.imageHero}
              alt=""
              loading="eager"
              className="img-fluid w-100"
              style={{ paddingRight: "2px" }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

/** export */
export default HeroInvestImageHero
