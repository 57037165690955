import React from "react"
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  image1: string
  imageAlt1: string

  image2: string
  imageAlt2: string

  image3: string
  imageAlt3: string

  image4: string
  imageAlt4: string

  image5: string
  imageAlt5: string
}

/** const */
const HeroInvestGallery: React.FC<Props> = (props) => {
  return (
    <div
      className="container-fluid text-center hero p-0 m-0"
      style={{
        color: "#FFF",
        backgroundColor: "#FFF",
      }}
    >
      <div className="jumbotron text-center">
        <div className="w-100">
          <div>
            <img
              src={props.image1}
              alt={props.imageAlt1}
              loading="eager"
              className="img-fluid w-50"
              style={{ paddingRight: "2px" }}
            />

            <img
              src={props.image2}
              alt={props.imageAlt2}
              loading="eager"
              className="img-fluid w-50"
              style={{ paddingLeft: "2px" }}
            />
          </div>

          <div>
            <img
              src={props.image3}
              alt={props.imageAlt3}
              loading="eager"
              className="img-fluid w-25"
              style={{
                paddingBottom: "4px",
                paddingTop: "4px",
                paddingRight: "2px",
              }}
            />

            <img
              src={props.image4}
              alt={props.imageAlt4}
              loading="eager"
              className="img-fluid w-25"
              style={{
                paddingBottom: "4px",
                paddingTop: "4px",
                paddingRight: "2px",
              }}
            />

            <img
              src={props.image5}
              alt={props.imageAlt5}
              loading="eager"
              className="img-fluid w-25"
              style={{
                paddingBottom: "4px",
                paddingTop: "4px",
                paddingLeft: "2px",
              }}
            />

            <img
              src={props.image6}
              alt={props.imageAlt6}
              loading="eager"
              className="img-fluid w-25"
              style={{
                paddingBottom: "4px",
                paddingTop: "4px",
                paddingLeft: "2px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

/** export */
export default HeroInvestGallery
