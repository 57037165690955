import React from "react"

interface Props {
  company: string
  title: string
  subtitle: string
  buttonName?: string
  buttonUrl?: string
}

/** const */
const FundRibbon: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section className="d-flex align-items-center">
      <div className="container-fluid" style={{ background: "#f4f4f4", color: "#111111" }}>
        <div className="row align-items-start justify-content-start">
          <div className="col-sm-6 mb-0 pb-0  invest-cta-left">
            <h6>{props.company}</h6>
            <strong>{props.title}</strong>
            <div className="mt-1" style={{ backgroundColor: "#dba000", width: "60%", height: "4px" }}></div>
          </div>
          <div className="col-sm-6 invest-cta-right">{props.subtitle}</div>
        </div>
      </div>
    </section>
  )
}

/** export */
export default FundRibbon
